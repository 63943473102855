.error-container {
  z-index: 1000;
  position: fixed;
  width: 40%;
  height: 30%;
  border: 1px solid black;
  top: 20%;
  left: 30%;
  background-color: rgb(255, 251, 246);

}

@media screen and (min-width: 100px) and (max-width: 900px) {
  .error-container {
    width: 70%;
    height: 30%;
    top: 20%;
    left: 15%;

  
  }
  
}