.balances-chart-section {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}

.balances-container-cell {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: space-between;
  padding: 10px;
}

.balances-table {
  border: 0.5px solid grey;
}
.balances-table td {
  border: none;
}

.balances-table-container {
  margin: auto auto;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30px;
  overflow: auto;

}
.balances-header {
  background-color: #696969;
  color: #ffffff;
  text-align: center;
  min-width: 110px;

}

.balances-header th {
  min-width: 130px;
}

.individual-balance {
  cursor: pointer;
  border: 1px solid #0096FF;
  border-radius: 5px;
  margin: 2px;
  margin-bottom: 5px;
  padding: 3px;
}

.individual-balance:hover {
  background-color: rgb(200, 229, 255);
}

.balances-table-bottom-row {
  background-color: #e1e1e1;
  font-weight: 500;
}