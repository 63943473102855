.by-day-table-container {
  overflow: auto;
}

.by-day-table {
  border-collapse: collapse;
  margin: 25px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgb(76, 76, 76);

}

.by-day-table-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
  margin-bottom: 10px;
}

.by-day-table-option {
  margin-right: 10px;
}

.table-header-cell {
  background-color: #696969;
  color: #ffffff;
  text-align: center;
  min-width: 80px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
}

table th {
  border: 0.5px solid rgb(76, 76, 76);
}

table td {
  border: 0.5px solid rgb(76, 76, 76);
}

.table-cell-with-spend:hover .button-add-spend-incell {
  border: 1px solid rgb(76, 76, 76);
  visibility: visible;
}


.table-cell-without-spend {}

.table-cell-without-spend:hover .button-add-spend-incell {
  border: 1px solid rgb(76, 76, 76);
  visibility: visible;
}

.table-cell-without-spend:hover {
  background-color: rgb(232, 240, 255);
}

.table-cell-without-spend .button-add-spend-incell {
  visibility: hidden;
  cursor: pointer;
  border-radius: 5px;
}

.table-cell-with-spend .button-add-spend-incell {
  visibility: hidden;
  cursor: pointer;
  border-radius: 5px;
}


.button-add-spend-incell {}


.table-totals {
  font-weight: 700;
}


.daily-table-bottom-row {
  background-color: #e1e1e1;
  font-weight: 500;
}