.admin-dashboar-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    margin-top: 20px;
}

.admin-dashboard-item {
    border: 1px solid black;
    height: 200px;
    width: 400px;
    margin-bottom: 20px;
}

.dashboard-feedback-item {
    margin-bottom: 10px;

}


.latest-rating-container {
    margin-left: 20%;
    margin-right: 20%;
}