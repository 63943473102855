

.all-expenses-table-container {
    overflow: auto;
}


.all-expenses-table{
    border-collapse: collapse;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
}
.all-expenses-table .expenses-thead{
    background-color: #696969;
    color: #ffffff;
    text-align: center;
    min-width: 110px;
}
.expense-search-box{
    border-radius: 3px;
    border: 0.5px solid rgb(53, 53, 245);
    height: 35px;
}

.expenses-thead {
    cursor: pointer;
}