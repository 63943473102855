

@media screen and (min-width: 100px) and (max-width: 700px) {
  .navbar-container{

  }
}

#header-full-logo {
  width: 110px;
  height: 48.53px;
  margin-top: 5px;
}

#header-full-logo:hover {
  width: 115px;
  height: 50.18px;
  margin-top: 3.35px;
}

.top-left-navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: baseline;
  text-align: left !important;
}

.top-right-navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around; 
}


@media screen and (min-width: 100px) and (max-width: 500px) {
  .top-right-navbar{
    flex-wrap: wrap;
    justify-content: center;
  }
}


.top-left-navbar-item {
  margin-right: 15px;
  border-radius: 5px;

  background-color: rgb(240, 240, 240);
}
.top-left-navbar-item-logo {
  margin-right: 15px;
}

@media screen and (min-width: 100px) and (max-width: 945px) {
  .top-left-navbar-item{
    margin-bottom: 10px;
  }
}

.text-left-navbar-text {
  text-decoration: none;
  color: black;
  padding-left: 4px;
  padding-right: 4px;
}

.text-left-navbar-text-selected {
  color: black !important;
  text-decoration: none;
  font-size: large;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  background-color: rgb(208, 208, 228);
}


.top-right-navbar-item {
  margin-right: 15px;
}

@media screen and (min-width: 100px) and (max-width: 500px) {
  .top-right-navbar-item{
    margin-bottom: 10px;
  }
}

.user-info-container {
  z-index: 5;
  width: 200px;
  background-color: rgb(243, 247, 250);
  border-radius: 3px;
  border: 1px solid black;
  text-align: left;
  position: absolute;
  top: 55px;
  right: 2px
}

.user-info-item {
  border-bottom: 0.5px solid grey;
  padding-bottom: 10px;
  padding-left: 4px;
}
.user-info-item-action {
  border-bottom: 0.5px solid grey;
  padding-bottom: 10px;
  padding-left: 4px;
  cursor: pointer;
}
.user-info-item-action:hover {
  border-bottom: 0.5px solid grey;
  padding-bottom: 10px;
  padding-left: 4px;
  font-weight: 600;
}

.user-profilepic {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
}


#feedback {
  cursor: pointer;
}
