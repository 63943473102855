.feedback-container {
  
    overflow: auto;
    margin-left: 3%;
    margin-right: 3%;
    max-width: 90%;
}

.feedback-item {
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    width: 400px;
    height: 200px;

    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
}


.feedback-subitem {
    text-align: left;
    margin-bottom: 5px;
}


.feedback-table {


}