.create-balance-modal {
  opacity: 10;
  position: fixed;
  border: 1px solid black;
  height: 420px;
  background-color: white;
  top: 0;
  left: 15%;
  right: 15%;
  width: 70%;
  /* margin-top: 55px; */
  padding-top: 20px;
}
@media screen and (min-width: 100px) and (max-width: 600px) {
  .create-balance-modal {
    
    left: 1%;
    right: 1%;
    width: 98%;
  }
}
.create-balance-fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}

.create-balance-input-item {
  width: 70%;
  margin-bottom: 10px;
}

.unverified-input {
  border-color: red;
}

.create-balance-checkbox {
  min-width: 150px;
  margin-bottom: 10px;
  text-align: left !important;
}